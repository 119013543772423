<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <edit-tab  :lista-unidade-medidas="listaUnidadeMedidas" :lista-marcas="listaMarcas" :lista-familias="listaFamilias" class="mt-1" />
  </component>
</template>

<script>
  import { BAlert, BCard, BLink, BTab, BTabs, } from 'bootstrap-vue'
  import { onUnmounted, ref } from '@vue/composition-api'
  import router from '@/router'
  import leadStoreModule from '../itemStoreModule'
  import EditTab from './EditTab.vue'
  import store from '@/store'

  export default {
    components: {
      BTab,
      BTabs,
      BCard,
      BAlert,
      BLink,
      EditTab,
    },
    setup() {
      const userData = ref({})

      const ITEM_APP_STORE_MODULE_NAME = 'produtos'

      // Register module
      if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, leadStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
      })

      const listaUnidadeMedidas = ref([])
      store.dispatch('produtos/fetchUnidadeMedida')
        .then(response => {
          listaUnidadeMedidas.value = response.data
        })

      const listaMarcas = ref([])
      store.dispatch('produtos/fetchMarca')
        .then(response => {
          listaMarcas.value = response.data
        })

        const listaFamilias = ref([])
      store.dispatch('produtos/fetchFamiliaProduto')
        .then(response => {
          listaFamilias.value = response.data
        })

  
      return {
        userData,
        listaUnidadeMedidas,
        listaMarcas,
        listaFamilias,
      }
    },
  }
</script>
